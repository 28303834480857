import Icon from 'rv-components-onze/src/Icon'
import { generalTerms, socialMediaLinks, usefulLinks } from './constants'
import Accordion from './components/Accordion'

import enhancer from './hooks'

const Footer = ({ isOnzeManager }) => {
  return (
    <div
      className={`footer-landing ${
        isOnzeManager ? 'footer-landing--onze-manager' : ''
      }`}>
      <div className="container footer-landing__content">
        <div className="footer-landing__main-info">
          <div className="footer-landing-main-info__onze">
            <div className="address">
              <Icon iconId="onze-logo" />
              <p className="footer-landing-main-info__description">
                Rua Olimpíadas, 242 - 4º andar
              </p>
              <p className="footer-landing-main-info__description">
                Itaim Bibi, São Paulo
              </p>
              <p className="footer-landing-main-info__description">
                contato@onze.com.br
              </p>

              <div className="social-links">
                {socialMediaLinks.map((item, index) => (
                  <a
                    className="social-links__item"
                    target="_blank"
                    title={`${item.icon} da Onze`}
                    href={item.link}
                    key={`${item.icon}-${index}`}>
                    <Icon iconId={`icon-${item.icon}`} />
                  </a>
                ))}
              </div>
            </div>
          </div>

          <div className="footer-landing-main-info__onze footer-landing-main-info__onze--desktop">
            <h4 className="footer-landing-main-info__title">Links úteis</h4>
            {usefulLinks.map((item, idx) => (
              <a
                key={idx}
                className="footer-landing-main-info__description"
                href={item.link}
                target="_blank">
                {item.text}
              </a>
            ))}

            <h4 className="footer-landing-main-info__description press-contact">
              Imprensa
            </h4>
            <p className="footer-landing-main-info__description footer-landing-main-info__media">
              rcovre@onze.com.br
            </p>
          </div>

          {!isOnzeManager && (
            <div className="footer-landing-main-info__onze footer-landing-main-info__onze--desktop">
              <h4 className="footer-landing-main-info__title">Termos legais</h4>

              {generalTerms.map((item, index) => (
                <a
                  className="footer-landing-main-info__description"
                  href={item.link}
                  title={item.name}
                  target="_blank"
                  key={`${item.name}-${index}`}>
                  {item.text}
                </a>
              ))}

              <h4 className="footer-landing-main-info__description press-contact">
                Privacidade e proteção de dados
              </h4>
              <p className="footer-landing-main-info__description footer-landing-main-info__media">
                Encarregado: Ronaldo Orbite
              </p>
              <p className="footer-landing-main-info__description footer-landing-main-info__media">
                dpo@onze.com.br 
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="footer-landing__mobile-accordions">
        <Accordion
          title="Links úteis"
          phrasesList={usefulLinks}
          showPressContact
        />
        {!isOnzeManager && (
          <Accordion
            title="Termos legais"
            phrasesList={generalTerms}
         />
        )}
      </div>

      {isOnzeManager && (
        <div className="footer-landing__rights rights">
          <div className="container footer-landing__content">
            <div className="rights__text">
              <p>
                © Onze Gestora de Investimentos Ltda. • CNPJ: 34.008.311/0001-19
                - Todos os direitos reservados
              </p>
            </div>
          </div>
        </div>
      )}

      {!isOnzeManager && (
        <div className="footer-landing__certifications rights">
          <div className="container">
            <p className={`footer-landing__certifications-description`}>
              A Onze Corretora de Seguros LTDA., inscrita sob o CNPJ
              29.178.278/0001-35, é uma instituição autorizada a funcionar pela
              SUSEP - Superintendência de Seguros Privados. Toda comunicação
              através da rede mundial de computadores está sujeita a
              interrupções ou atrasos, podendo prejudicar a troca de informações
              atualizadas momentaneamente. A Onze exime-se de responsabilidade
              por danos sofridos por seus clientes ou por força de falha de
              serviços disponibilizados por terceiros.
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

export default enhancer(Footer)
